const theme = {
  title: 'light',
  font: {
    family: "'Roboto', sans-serif",
    light: 300,
    normal: 400,
    bold: 600,
    bolder: 700,
    sizes: {
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      modalTitle: '2.4rem',
      xxlarge: '2.8rem',
    },
  },
  colors: {
    primary: '#30696e',
    secondary: '#55C5D0',
    menuSecundario:"#222834",
    listText: '#6A737D',
    darkGray: '#7D7D7D',
    yellow: '#FFAB00',
    white: '#ffffff',
    black: '#030517',
    blackReverse: '#000000',
    lightGray: '#DFE3E8',
    gray: '#D3D4D5',
    darkGrayPlaceholder: '#6A737D',
    label: '#000723',
    loginLabel: '#030517',
    code: '#f6f8fa',
    borderGray: '#DFE3E8',

  },
  menuLeft: {
    hover: "#474d5b",
    color: "#9fa6bc",
    bg: "#31374a",
    active: "#FFF"
  },
  border: {
    radius: '0.4rem',
    color: '#DFE3E8'
  },
  bg: {
    custom: '#F5F5F5',
    primary: '#31374a',
    secundary: '#ffffff',
    tertiary: '#FFFFFF',
  },
  hover: {
    backgroundColor: '#DFE3E8',
  },
};

export default theme;
