import React from 'react'
import * as S from './styles'
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material'
import { InputSelect, TableComponet, Titulo } from '../../components'
import { useState } from 'react'
import { FilterAltOff, SearchOutlined } from '@mui/icons-material'
import { Mask, Utils, api } from '../../utilities'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

function LogsAdmin({ empresa, user }) {
  const [loading, setLoading] = useState(false)
  const dataAtual = new Date()

  // Definir a data para o primeiro dia do mês atual
  const primeiroDiaMes = new Date(
    dataAtual.getFullYear(),
    dataAtual.getMonth(),
    1,
  )
  // Obter o dia atual
  const diaAtual = new Date(
    dataAtual.getFullYear(),
    dataAtual.getMonth(),
    dataAtual.getDate(),
  )

  const [filtros, setFiltros] = React.useState({
    pagina: 1,
    tamanhoPagina: 10,
    dataInicial: null, //primeiro dia do mes atual
    dataFinal: null, //dia atual do mes atual
    descricao: null,
    idEmpresa: null,
    idUsuario: null,
    tipoLog: null,
  })
  const [empresas, setEmpresas] = React.useState([])
  const [totalizador, setTotalizador] = React.useState(0)
  const [tiposLogs, setTiposLogs] = React.useState([])
  const [logs, setLogs] = React.useState([])

  const headers = [
    {
      id: 'strDataCadastro',
      label: 'Cadastro',
      props: {
        align: 'center',
      },
    },
    {
      id: 'strDataFinal',
      label: 'Fim',
      props: {
        align: 'center',
      },
    },
    {
      id: 'empresaFantasia',
      label: 'Empresa',
      props: {
        align: 'left',
      },
    },
    {
      id: 'usuarioNome',
      label: 'Usuário',
      props: {
        align: 'left',
      },
    },
    {
      id: 'usuarioLogin',
      label: 'Login',
      props: {
        align: 'left',
      },
    },
    {
      id: 'descricaoRelatorio',
      label: 'Relatório',
      props: {
        align: 'left',
      },
    },
    {
      id: 'localizacao',
      label: 'Local',
      props: {
        align: 'left',
      },
    },
    {
      id: 'descricaoTipoLog',
      label: 'Tipo de Log',
      props: {
        align: 'left',
      },
    },

    // {
    //   id: 'actionRows',
    //   label: 'Editar',
    //   props: {
    //     align: 'right',
    //   },
    // },
  ]

  let mounted = true
  React.useEffect(() => {
    async function getFiltros() {
      if (mounted) {
        let userTipo = Utils.isNotNull(user) ? user.tipo : null

        let dataAtual = new Date()

        // Definir a data para o primeiro dia do mês atual
        let primeiroDiaMes = new Date(
          dataAtual.getFullYear(),
          dataAtual.getMonth(),
          1,
        )
        // Obter o dia atual
        let diaAtual = new Date(
          dataAtual.getFullYear(),
          dataAtual.getMonth(),
          dataAtual.getDate(),
        )

        setLoading(true)
        if (userTipo === 'ADMIN_MOBIOH') {
          api
            .post('/usuario/admin/mobioh/log/dados', {
              dataInicial: Mask.maskDatePattern(
                primeiroDiaMes,
                'YYYY-MM-DD HH:mm:ss.S',
              ),
              dataFinal: Mask.maskDatePattern(
                diaAtual,
                'YYYY-MM-DD HH:mm:ss.S',
              ),
              pagina: 1,
              tamanhoPagina: 10,
            })
            .then(({ data }) => {
              if (Utils.isNotObjectEmpty(data)) {
                setEmpresas(data.empresas)
                setTiposLogs(data.tiposLogs)
                setTotalizador(data.totalRegistros)
                setLogs(data.totalRegistros > 0 ? data.logs : [])
                setLoading(false)
                setFiltros({
                  ...filtros,
                  dataInicial: dayjs(
                    Mask.maskDatePattern(primeiroDiaMes, 'YYYY-MM-DD'),
                  ),
                  dataFinal: dayjs(
                    Mask.maskDatePattern(diaAtual, 'YYYY-MM-DD'),
                  ),
                })
              }
            })
            .catch((error) => {
              setLoading(false)
              toast.error('Houve um problema ao buscar os logs!', {
                theme: 'colored',
              })
            })
        } else {
          api
            .post('/usuario/admin/cliente/log/dados', {
              dataInicial: primeiroDiaMes,
              dataFinal: diaAtual,
              pagina: 1,
              tamanhoPagina: 10,
              idUsuarioAdmin: user.idUsuario,
            })
            .then(({ data }) => {
              if (Utils.isNotObjectEmpty(data)) {
                setEmpresas(data.empresas)
                setTiposLogs(data.tiposLogs)
                setLogs(data.logs)
                setTotalizador(data.totalRegistros)
                setLoading(false)
              }
            })
            .catch((error) => {
              setLoading(false)
              toast.error('Houve um problema ao buscar os logs!', {
                theme: 'colored',
              })
            })
        }
      }
    }

    getFiltros()
    return () => (mounted = false)
  }, [])

  async function buscarLogsTabela(pagina, tamanhoPagina) {
    setLoading(true)

    let userTipo = Utils.isNotNull(user) ? user.tipo : null

    let newObj = {
      ...filtros,
      dataInicial: Mask.maskDatePattern(
        new Date(filtros.dataInicial),
        'YYYY-MM-DD',
      ), //primeiro dia do mes atual
      dataFinal: Mask.maskDatePattern(
        new Date(filtros.dataFinal),
        'YYYY-MM-DD',
      ),
      pagina,
      tamanhoPagina,
    }

    if (userTipo === 'ADMIN_MOBIOH') {
      api
        .post('/usuario/admin/mobioh/log/filtro', newObj)
        .then(({ data }) => {
          setLoading(false)

          if (Utils.isArrayNotEmpty(data)) {
            setLogs(data)
            return data
          } else {
            setLogs([])
            return []
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema ao buscar os logs!', {
            theme: 'colored',
          })
        })
    } else {
      api
        .post('/usuario/admin/cliente/log/filtro', {
          ...newObj,
          idUsuarioAdmin: user.idUsuario,
        })
        .then(({ data }) => {
          setLoading(false)

          if (Utils.isArrayNotEmpty(data)) {
            setLogs(data)
            return data
          } else {
            setLogs([])
            return []
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema ao buscar os logs!', {
            theme: 'colored',
          })
        })
    }

    return []
  }

  function buscarMobioh(pagina, tamanhoPagina) {
    setLoading(true)

    let userTipo = Utils.isNotNull(user) ? user.tipo : null

    let newObj = {
      ...filtros,
      dataInicial: Mask.maskDatePattern(
        new Date(filtros.dataInicial),
        'YYYY-MM-DD HH:mm:ss.S',
      ), //primeiro dia do mes atual
      dataFinal: Mask.maskDatePattern(
        new Date(filtros.dataFinal),
        'YYYY-MM-DD HH:mm:ss.S',
      ),
      pagina,
      tamanhoPagina,
    }

    if (userTipo === 'ADMIN_MOBIOH') {
      api
        .post('/usuario/admin/mobioh/log/filtro', newObj)
        .then(({ data }) => {
          setLoading(false)

          if (Utils.isArrayNotEmpty(data)) {
            setLogs(data)
          } else {
            setLogs([])
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema ao buscar os logs!', {
            theme: 'colored',
          })
        })

      api
        .post('/usuario/admin/mobioh/log/totalizador', newObj)
        .then(({ data }) => {
          setLoading(false)

          if (Utils.isNotObjectEmpty(data)) {
            setTotalizador(data.totalRegistros)
          } else {
            setTotalizador(0)
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema ao buscar os logs!', {
            theme: 'colored',
          })
        })
    } else {
      api
        .post('/usuario/admin/cliente/log/filtro', {
          ...newObj,
          idUsuarioAdmin: user.idUsuario,
        })
        .then(({ data }) => {
          setLoading(false)

          if (Utils.isArrayNotEmpty(data)) {
            setLogs(data)
          } else {
            setLogs([])
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema ao buscar os logs!', {
            theme: 'colored',
          })
        })

      api
        .post('/usuario/admin/cliente/log/totalizador', {
          ...newObj,
          idUsuarioAdmin: user.idUsuario,
        })
        .then(({ data }) => {
          setLoading(false)

          if (Utils.isNotObjectEmpty(data)) {
            setTotalizador(data.totalRegistros)
          } else {
            setTotalizador(0)
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema ao buscar os logs!', {
            theme: 'colored',
          })
        })
    }
  }

  return (
    <S.Container>
      <Titulo>
        <div>Logs</div>
      </Titulo>
      <S.ContainerForm>
        <Grid container padding={1} spacing={2}>
          <Grid xs={12} md={6} item>
            <DatePicker
              label="Data Inicial *"
              inputFormat="DD/MM/YYYY"
              value={filtros.dataInicial}
              onChange={(e) => {
                setFiltros((state) => {
                  return { ...state, dataInicial: e }
                })
              }}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField size="small" {...params} />}
              className="data-input"
            />
          </Grid>

          <Grid xs={12} md={6} item>
            <DatePicker
              label="Data Final *"
              inputFormat="DD/MM/YYYY"
              value={filtros.dataFinal}
              onChange={(e) => {
                setFiltros((state) => {
                  return { ...state, dataFinal: e }
                })
              }}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} />}
              className="data-input"
            />
          </Grid>
          <Grid xs={12} md={4} item>
            <InputSelect
              value={filtros.idEmpresa}
              setValue={(value) => {
                setFiltros((state) => ({
                  ...state,
                  idEmpresa: value,
                }))
              }}
              options={empresas}
              label="Empresas"
              id="idEmpresa"
              descricao="fantasia"
              //size="small"
              sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
            />
          </Grid>

          <Grid xs={12} md={4} item>
            <InputSelect
              value={filtros.tipoLog}
              setValue={(value) => {
                setFiltros((state) => ({
                  ...state,
                  tipoLog: value,
                }))
              }}
              options={tiposLogs}
              label="Tipo de Log"
              id="tipoLog"
              descricao="descricaoTipoLog"
              //size="small"
              sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
            />
          </Grid>

          <Grid xs={12} md={4} item display={'flex'}>
            <Button
              className="confirm-button"
              variant="contained"
              size="large"
              startIcon={
                loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  <SearchOutlined />
                )
              }
              disabled={loading}
              sx={{ height: '53px' }}
              onClick={() => buscarMobioh(1, 10)}
            >
              Buscar
            </Button>

            <Tooltip
              title={'Limpar Filtros'}
              placement="top"
              style={{ fontSize: '20px !important' }}
            >
              <Button
                className="confirm-button"
                variant="text"
                size="large"
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    <FilterAltOff />
                  )
                }
                disabled={loading}
                sx={{ height: '53px', color: '#C4C4C4 !important' }}
                onClick={() => {

                  setFiltros((state) => ({
                    ...state,
                    pagina: 1,
                    tamanhoPagina: 10,
                    descricao: '',
                    idEmpresa: null,
                    tipoLog: null,
                  }))
                }}
              ></Button>
            </Tooltip>
          </Grid>
          <Grid xs={12} item marginTop={3}>
            <span>{'(' + totalizador + ') Logs encontrados'}</span>
          </Grid>
          <Grid xs={12} item>
            <TableComponet
              headers={headers}
              data={logs}
              labelCaption="Nenhum Log Encontrado Encontrado"
              labelTable="Lista de Usuários"
              status="ativo"
              statusLabelTrue="Ativo"
              statusLabelFalse="Inativo"
              loading={loading}
              request
              qdtPage={
                totalizador > 0
                  ? (totalizador / filtros.tamanhoPagina).toFixed()
                  : totalizador
              }
              setData={setLogs}
              handlerRequest={async (page, size) => {
                setLoading(true)
                if (
                  Utils.isNotNull(filtros.dataInicial) ||
                  Utils.isNotNull(filtros.dataFinal)
                ) {
                  buscarLogsTabela(page, size)
                }
                return []
              }}
            />
          </Grid>
        </Grid>
      </S.ContainerForm>
    </S.Container>
  )
}

const mapStateToProps = (store) => ({
  user: store.loginState.user.usuario,
  empresa: store.empresaReducer.empresa,
})

export default connect(mapStateToProps)(LogsAdmin)
