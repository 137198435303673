import styled from 'styled-components'
import { Button, TextField } from '@mui/material'

export const Container = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

 

  background-color: ${(props) => props.theme.bg.primary};
`

export const BoxLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 400px;
  min-height: 500px;

  //border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 10px;

  padding: 50px 10px 10px;

  background: ${(props) => props.theme.colors.white};

  input {
    font-size: 16px !important;
    padding: 8px !important;
  }
  button.help {
    margin: 15px 0 0 0;
    background-color: white !important;
    color: ${(props) => props.theme.colors.label};
    border: 1px solid ${(props) => props.theme.colors.gray};
  }
`

export const Image = styled.img`
  width: 165px;
  margin-bottom: 5px;
`
export const Title = styled.h6`
  font-size: 18px;
  font-weight: 300;

  margin: 7px 0;
  color: ${(props) => props.theme.colors.darkGray};
`

export const Divider = styled.hr`
  width: 40px;
  height: 3px;
  //background: ${(props) => props.theme.colors.green};
  //border: 1px solid ${(props) => props.theme.colors.green};
  background: black;
  border: 1px solid black;

  border-radius: 10px;
`
export const StyledTextField = styled(TextField)`
  width: 85%;
  font-size: 17px !important;

  margin: 25px 0 0 0 !important;
`
export const StyledA = styled.a`
  font-size: 13px;
  cursor: pointer;
`
export const StyledBoxPassword = styled.div`
  display: flex;
  justify-content: space-around;

  width: 60%;
  margin: 10px 0 10px 0;

  color: ${(props) => props.theme.colors.listText};

  a:hover {
    color: ${(props) => props.theme.colors.loginLabel};
  }
`

export const StyledButton = styled(Button)`
  width: 75%;
  margin: 20px 0px 0px !important;

  background-color: ${(props) => props.theme.colors.button} !important;
  font-size: 11px !important;
`
