import React from 'react'
import MenuTopUser from './components/MenuTopUser/MenuTopUser'
import { Grid } from '@mui/material'
import { DrawerBar } from './components'
import { logout } from './service/auth'
import { connect } from 'react-redux'

function App(props) {
  const { tipoMenu } = props.user

  return (
    <Grid
      width={'100vw'}
      minHeight={'100%'}
      className="bg-body"
      key={'appInit'}
    >
      {tipoMenu === 'HORIZONTAL' ? (
        <div style={{ paddingRight: '0px' }}>
          <MenuTopUser />
          {props.children}
        </div>
      ) : (
        <DrawerBar
          logo="Power BI"
          user={{ nome: '', img: '' }}
          history={props.history}
          logout={logout}
          key={'DrawerBar'}
        >
          <div>{props.children}</div>
        </DrawerBar>
      )}
    </Grid>
  )
}

const mapStateToProps = (store) => ({
  user: store.loginState.user.usuario,
})

export default connect(mapStateToProps)(App)

